<template>
    <el-dialog title="查看" width="70%" top="2vh" :close-on-click-modal="false" :visible.sync="visible">
        <div class="dialogBox">
            <el-descriptions title="订单信息" :column="2" class="b_b p_b1">
                <el-descriptions-item label="订单编号">{{info.outTradeNo}}</el-descriptions-item>
                <el-descriptions-item label="下单时间">{{info.createTime}}</el-descriptions-item>
                <el-descriptions-item label="交易平台号">{{info.tradeNo}}</el-descriptions-item>
                <el-descriptions-item label="支付时间">{{info.payDate}}</el-descriptions-item>
                <el-descriptions-item label="订单状态">
                    <el-tag size="small" v-if="info.payState">
                        {{$dictUtils.getDictLabel("order_status",info.payState,'')}}
                    </el-tag>
                </el-descriptions-item>
            </el-descriptions>
            <el-descriptions title="参观信息" :column="2" class="b_b p_t2 p_b1">
                <el-descriptions-item label="参观日期">{{info.makeDate}}</el-descriptions-item>
                <el-descriptions-item label="入馆时段">{{info.storehouseTime}}</el-descriptions-item>
                <el-descriptions-item label="联系人">{{info.ticketingVerbName}}</el-descriptions-item>
                <el-descriptions-item label="联系电话">{{info.ticketingVerbPhone}}</el-descriptions-item>
                <el-descriptions-item label="总价（元）">{{info.countMoney}}</el-descriptions-item>
                <el-descriptions-item label="参观人数">{{info.ticketingNoun}}</el-descriptions-item>
                <el-descriptions-item label="授权电话">{{info.authorizationPhone}}</el-descriptions-item>
                <el-descriptions-item label="预约状态">{{$dictUtils.getDictLabel("ticketing_state",info.ticketingState,'')}}
                </el-descriptions-item>
            </el-descriptions>
            <div class="el-descriptions__title el-descriptions p_t2 p_b1">门票信息</div>
            <el-table :data="info.sysTicketingMakeDetailVoList" border size="small" style="width: 100%">
                <el-table-column prop="ticketingVisitorUser" label="姓名" show-overflow-tooltip></el-table-column>
                <el-table-column prop="cardTypeName" label="证件类型" show-overflow-tooltip></el-table-column>
                <el-table-column prop="ticketingVisitorCard" label="证件号码" width="120"
                                 show-overflow-tooltip></el-table-column>
                <el-table-column prop="ticketingValidationCode" label="票号" width="120"
                                 show-overflow-tooltip></el-table-column>
                <el-table-column prop="ticketingVisitorState" label="参观状态" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{$dictUtils.getDictLabel("visitor_state",scope.row.ticketingVisitorState,'')}}
                    </template>
                </el-table-column>
                <el-table-column prop="ticketingValidationTime" label="参观时间" show-overflow-tooltip></el-table-column>
                <el-table-column prop="outRequestNo" label="退款单号" show-overflow-tooltip></el-table-column>
                <el-table-column prop="payState" label="退款状态" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{$dictUtils.getDictLabel("order_status",scope.row.payState,'-')}}
                    </template>
                </el-table-column>
                <el-table-column prop="outRequestDate" label="退款时间" show-overflow-tooltip></el-table-column>
                <el-table-column prop="createOutRequestDate" label="退票时间" show-overflow-tooltip></el-table-column>
            </el-table>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button size="small" @click="visible = false">关闭</el-button>
        </span>
    </el-dialog>
</template>

<script>
    export default {
        data() {
            return {
                visible: false,
                info: {},
            }
        },
        methods: {
            init(row) {
                this.visible = true
                this.$axios(this.api.order.sysGetTicketingMakeById + '/' + row.id).then((res) => {
                    if (res.status) {
                        this.info = res.data
                    }
                })
            },
        }
    }
</script>

<style scoped>

</style>
